import Auth from './auth/reducer';
import Qi from './qi/reducer';
import Organisation from './organisation/reducer';
import App from './app/reducer';

import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';

import modal from './modal/reducer';

export default {
    Auth,
    App,
    ThemeSwitcher,
    LanguageSwitcher,


    modal,
    Qi,
    Organisation,
};
