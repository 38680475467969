import actions from "./actions";

const initState = {
    rolle: {
        list: [],
        single: {}
    },
    einrichtung: {
        list: [],
        singles: {}
    },
    user: {
        list: [],
        listEinladung: []
    },
    org: {
        defaultRolle: ""
    }
};

export default function authReducer(state = initState, action) {
    const rolle = {...state.rolle}
    const einrichtung = {...state.einrichtung}
    const user = {...state.user}
    switch (action.type) {
        case actions.ROLLE_LISTE_LOAD_SUCCESS:
            rolle.list = action.payload
            return {
                ...state,

                rolle
            };
        case actions.Einrichtung_LISTE_LOAD_SUCCESS:
            einrichtung.list = action.payload
            return {
                ...state,

                einrichtung
            };
        case actions.ORG_LOAD_SUCCESS:
            const org = action.payload
            return {
                ...state,

                org
            };
        case actions.USER_LISTE_LOAD_SUCCESS:
            user.list = action.payload
            return {
                ...state,
                user
            };
        case actions.USER_Einladung_LISTE_LOAD_SUCCESS:
            user.listEinladung = action.payload;
            return {
                ...state,
                user
            };
        case actions.ROLLE_SINGLE_LOAD_SUCCESS:
            rolle.single = action.payload
            return {
                ...state,

                rolle
            };
        case actions.LOGOUT:
            return initState;
        default:
            return state;
    }
}
