 import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
    /* {
       path: 'dashboards',
         exact: true,
   
         component: asyncComponent(() => import('../Dashboards')),
     },
     */
    {
        path: 'anfrages',
        exact: true,

        component: asyncComponent(() => import('../Anfrage')),
    }, {
        path: 'einrichtungs',
        exact: true,

        component: asyncComponent(() => import('../Einrichtung')),
    }, {
        path: '',
        exact: true,

        component: asyncComponent(() => import('../Dashboards')),
    },

    {
        path: 'organisation',
        component: asyncComponent(() => import('../Organisation')),
        exact: false,
    },
    {
        path: 'linktoapp',
        component: asyncComponent(() => import('../Organisation/LinkToApp')),
        exact: false,
    }, {
        path: 'chat',
        component: asyncComponent(() => import('../Chat')),
        exact: false,
    }];

class AppRouter extends Component {
    render() {
        const {url, style} = this.props;
        return (
            <div style={style}>
                {routes.map(singleRoute => {
                    const {path, exact, ...otherProps} = singleRoute;
                    return (
                        <Route
                            exact={exact === false ? false : true}
                            key={singleRoute.path}
                            path={`${url}/${singleRoute.path}`}
                            {...otherProps}
                        />
                    );
                })}
            </div>
        );
    }
}

export default AppRouter;


