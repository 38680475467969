import React, {Component} from 'react';
import {connect} from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import userpic from '../../image/user1.png';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import Organisation from "./../../components/thallux/newComponents/Organisation/drawer"
const {logout} = authAction;

class TopbarUser extends Component {
    constructor(props) {
        super(props);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hide = this.hide.bind(this);
        this.state = {
            visible: false,
            showOrganisation:false,
        };
    }

    hide() {
        this.setState({visible: false});
    }

    handleVisibleChange() {
        this.setState({visible: !this.state.visible});
    }

    render() {
        const content = (
            <TopbarDropdownWrapper className="isoUserDropdown">
                {/*
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="themeSwitcher.settings" />
        </a>
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="sidebar.feedback" />
        </a>
        */}
        <a className="isoDropdownLink" onClick={()=>{this.setState({showOrganisation:true,visible:false})}}>
          <IntlMessages id="topbar.organisation" />
        </a>

                <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
                    <IntlMessages id="topbar.logout"/>
                </a>
            </TopbarDropdownWrapper>
        );

        return ([
                (this.state.showOrganisation&&<Organisation onClose={()=>{this.setState({showOrganisation:false})}}/>), <Popover
                content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                arrowPointAtCenter={true}
                placement="bottomLeft"
            >
                <div className="isoImgWrapper">
                    <i className={"ion-person"} style={{fontSize: 37}}/>
                    <span className="userActivity online"/>
                </div>
            </Popover>]

        );
    }
}

export default connect(
    null,
    {logout}
)(TopbarUser);
