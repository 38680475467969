const RechteStringHelper = {
    stringToIntArray: function (string) {
        const array = []
        for (let item in string) {
            array.push(string.charCodeAt(item))
        }
        return array
    },
    fillWithZeroBack: function (zahl, size) {

        while (zahl.length < size) {
            zahl = zahl + "0";
        }
        return zahl;
    }, fillWithZeroFront: function (zahl, size) {

        while (zahl.length < size) {
            zahl = "0" + zahl;
        }
        return zahl;
    }, toRightString: function (array) {

        const stringOfAll = array.map(x => (x ? 1 : 0)).join("").match(/.{1,16}/g).map(x => RechteStringHelper.fillWithZeroFront(x, 16))
        return stringOfAll.map(x => String.fromCharCode(parseInt(x, 2))).join("")
    }, toBinString: (string) => {

        const s = RechteStringHelper.stringToIntArray(string).map(x => x.toString(2)).map(x => RechteStringHelper.fillWithZeroFront(x, 16));

        return s.join("")

    },
    can: (string, rechtBit) => {
        return string[rechtBit] == "1"

        const binStrting = RechteStringHelper.toBinString(string)

        if (!Array.isArray(rechtBit)) {
            rechtBit = [rechtBit]
        } else {
            if (rechtBit.length == 0) {
                return true;
            }
        }
        rechtBit = rechtBit.map(x => binStrting[x] == "1").reduce((x, y) => (x && y));

        return rechtBit

    }

}
export default RechteStringHelper