import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {connect} from 'react-redux';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({component: Component, isLoggedIn, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/signin',
                        state: {from: props.location},
                    }}
                />
            )
        }
    />
);
const PublicRoutes = ({history, isLoggedIn}) => {
    return (
        <ConnectedRouter history={history}>
            <div>
                <Route
                    exact
                    path={'/'}
                    component={asyncComponent(() => import('./containers/Page/signin'))}
                />
                <Route
                    exact
                    path={'/404'}
                    component={asyncComponent(() => import('./containers/Page/404'))}
                /> <Route
                path={'/chatbox/:meId/:signatur/:conversationId'}
                component={asyncComponent(() => import('./containers/Page/Chatbox'))}
            />

                <Route
                    exact
                    path={'/500'}
                    component={asyncComponent(() => import('./containers/Page/500'))}
                />
                <Route
                    exact
                    path={'/signin'}
                    component={asyncComponent(() => import('./containers/Page/signin'))}
                />

                <Route
                    exact
                    path={'/forgotpassword'}
                    component={asyncComponent(() =>
                        import('./containers/Page/forgotPassword')
                    )}
                />

                <Route
                    exact
                    path={'/loginViaToken/:token'}
                    component={asyncComponent(() =>
                        import('./containers/Page/loginViaToken')
                    )}
                />
                <Route

                    path={'/passwort/vergessenUndSetzen/:id/:token'}
                    component={asyncComponent(() =>
                        import('./containers/Page/passwortVergessenUndSetzen')
                    )}
                />


                <RestrictedRoute
                    path="/dashboard"
                    component={App}
                    isLoggedIn={isLoggedIn}
                />
            </div>
        </ConnectedRouter>
    );
};

export default connect(state => ({
    isLoggedIn: state.Auth.token !== null,
}))(PublicRoutes);
