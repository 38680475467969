import React, {useEffect, useState} from "react"
import {Button, Table, Popover, Modal} from "antd";
import {useQuery} from "@apollo/react-hooks";
import _ from "underscore"
import {gql} from "apollo-boost"
import {connect} from "react-redux";
import orgAuth from "../../../../redux/organisation/actions";
import Authactions from "../../../../redux/auth/actions";
import SupportAnfrageNeu from "./../SupportAnfrageNeu/drawer"
import { Select ,Input,Form} from 'antd';
import moment from "moment";
import "moment/locale/de";

const App = (props)=>{

    const load=()=>{
        props.all_supportTickets().then(dat=>{
            set_data(dat)
        })
    }
    const [data,set_data]=useState([])
    const [details,set_details]=useState(null)
    const [showNeu,set_showNeu]=useState(false)


    useEffect(()=>{
        load()
    },[])

    return <div>

        {details!=null&&<Modal  onOk={()=>set_details(null)}  footer={null} onCancel={()=>set_details(null)} visible={true}>
            <div style={{    whiteSpace: "pre-line"}}>
            {details}
            </div>
        </Modal>}
        <Table dataSource={data} columns={[
            {key:"Subject",dataIndex:"Subject",title:"Betreff"},
            {key:"Status",dataIndex:"Status",title:"Status"},
            {key:"n",title:"",render:(v,item)=>{
                return <Button type="primary" onClick={()=>set_details(item.Description)}>Mehr Infos</Button>
                }}
        ]}></Table>

        {showNeu&&<SupportAnfrageNeu onComplete={()=>{
            load()
            alert("Entgegengenommen")
            set_showNeu(false)
        }} onClose={()=>set_showNeu(false)}/>}

        <div
            style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }}
        >






            <Button onClick={props.onClose} style={{marginRight: 8}}>
                Abbrechen
            </Button>


            {props.checkAllow(53)&&
                <Button type="primary" onClick={()=>set_showNeu(true)}> Neues Ticket
                </Button>}



        </div>

    </div>

}



const Comp =
    connect((state) => {
        return {
            state,
        }
    }, {
        all_supportTickets:orgAuth.all_supportTickets,
        checkAllow:Authactions.checkAllow

    })(App);


export default Comp;