import React from 'react';
import {Provider} from 'react-redux';
import {store, history} from './redux/store';
import PublicRoutes from './router';
import {ThemeProvider} from 'styled-components';
import {LocaleProvider} from 'antd';
import {IntlProvider} from 'react-intl';
import themes from './settings/themes';
import AppLocale from './languageProvider';
import config, {
    getCurrentLanguage,
} from './containers/LanguageSwitcher/config';
import {themeConfig} from './settings';
import DashAppHolder from './dashAppStyle';
import Boot from './redux/boot';
import GlobalStyles from './static/style/globalStyle';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import {versionsConfig} from "./configs"
const client = new ApolloClient({
    uri:versionsConfig.graphqlUrl,
});

const currentAppLocale =
    AppLocale[getCurrentLanguage(config.defaultLanguage || 'deutsch').locale];

const DashApp = () => (
    <ApolloProvider client={client}>

    <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <ThemeProvider theme={themes[themeConfig.theme]}>
                <DashAppHolder>
                    <Provider store={store}>
                        <PublicRoutes history={history}/>
                    </Provider>
                    <GlobalStyles/>
                </DashAppHolder>
            </ThemeProvider>
        </IntlProvider>
    </LocaleProvider>
    </ApolloProvider>
);
Boot()
    .then(() => DashApp())
    .catch(error => console.error(error));

export default DashApp;
export {AppLocale};
