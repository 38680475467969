import React from 'react';
import {Link} from 'react-router-dom';
import {siteConfig} from '../../settings';
import logo from '../../image/logo2.png';
import logoThallux from '../../image/menulogo.png';

export default ({collapsed}) => {

    return (
        <div className="isoLogoWrapper">
            {collapsed ? (
                <div>
                    <h3>
                        <Link to="/dashboard">
                            <img style={{maxWidth: "60px", maxHeight: "60px"}} src={logoThallux}/>
                        </Link>
                    </h3>
                </div>
            ) : (
                <img style={{maxWidth: "100%", maxHeight: "60px"}} src={logo}/>
            )}
        </div>
    );
};
