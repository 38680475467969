import React, {useEffect, useState} from "react"
import {Button, Table} from "antd";
import {useQuery} from "@apollo/react-hooks";
import _ from "underscore"
import {gql} from "apollo-boost"
import {connect} from "react-redux";
import orgAuth from "../../../../redux/organisation/actions";
import qiAction from "../../../../redux/qi/actions";
import { Select ,Input,Form} from 'antd';
import moment from "moment";
import "moment/locale/de";
import {set} from "react-ga";
import Authactions from "../../../../redux/auth/actions";

const { Option } = Select;


const GET_Anfrage_data=gql`
    query GET_Anfrage_data {me{
        name
        organisation{
            salesforceId
            kennung

        }
        user{
            telefon
            loginTag

        }
    }
    }
`


const App = (props)=>{

    const [bewohnercode,set_bewohnercode] = useState(props.bewohnercode||null)
    const [data,set_data] = useState({message:"",user:{},org:{}})

    const query_data = useQuery(GET_Anfrage_data,{context:{headers: {
                authorization:  "Bearer "+props.state.Auth.token
            }},variables:{_id:props.einrichtungId}})


    useEffect(()=>{
        if(!(query_data.error)&&(!query_data.loading)){
            console.log(query_data.data)
            let dd = {...data}
            dd.user.name = query_data.data.me.name
            dd.user.telefon = query_data.data.me.user.telefon
            dd.user.loginTag = query_data.data.me.user.loginTag
            dd.org.salesforceId = query_data.data.me.organisation.salesforceId
            dd.org.kennung = query_data.data.me.organisation.kennung
            set_data(dd)

        }
    },[query_data])

    

    return <div>
<Form {...{
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
}}>



    <Form.Item label="Organisationskennung">
        <Input value={data.org.kennung} disabled/>
    </Form.Item>

    <Form.Item label="Ihre Telefonnummer">
        <Input value={data.user.telefon} disabled/>
    </Form.Item>
    <Form.Item label="Ihr Name">
        <Input value={data.user.name} disabled/>
    </Form.Item>
    <Form.Item label="Ihr LoginTag">
        <Input value={data.user.loginTag} disabled/>
    </Form.Item>


    <Form.Item label="Betreff">
        <Input value={data.subject} onChange={(v)=>{
            let ldata = {...data}
            ldata.subject = (v.currentTarget.value)
            set_data(ldata)
        }}/>
    </Form.Item>


    <Form.Item label="Nachricht">
        <Input.TextArea value={data.message}   autoSize={{ minRows: 6 }} onChange={(v)=>{
            let ldata = {...data}
            ldata.message = (v.currentTarget.value)
            set_data(ldata)
        }}/>
    </Form.Item>



</Form>

        <div
            style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }}
        >






            <Button onClick={props.onClose} style={{marginRight: 8}}>
                Abbrechen
            </Button>


            {props.checkAllow(53)&&
            <Button type="primary" onClick={()=>{
                if(window.confirm("Wollen Sie diese Supportanfrage stellen?")){
                    props.sendSupportTicket(data).then(x=>{
                        props.onComplete()
                    })
                }



            }}>  Ticket anlegen
            </Button>}



        </div>
    </div>

}



const Comp =
    connect((state) => {
        return {
            state,
        }
    }, {
        checkAllow:Authactions.checkAllow,
        sendSupportTicket:orgAuth.sendSupportTicket


    })(App);


export default Comp;