import React, {useState} from 'react';
import "react-tabs/style/react-tabs.css";
import {connect} from 'react-redux';
import orgAuth from "../../../../redux/organisation/actions"
import PricingModal from "./../PricingModal/modal"
import {Button} from "antd";

/**
 *Die Komponente verarbeitet die gesammten Stammdaten des Auftrages.
 **/



const log = (type) => console.log.bind(console, type);

function Comp(props){

    const [princing,setPricing]= useState(false)
    return <div style={{height:"800px"}}>
        <iframe src={"https://www.thallux.de/werbung"} style={{width:"100%",height:"100%",overflow:"hide"}}></iframe>
        <div
            style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }}
        >
            {princing&&<PricingModal onClose={()=>{
                console.log("das")
                setPricing(false)
            }}/>}

            <Button type={"primary"} onClick={()=>setPricing(true)}>
                Zum Angebot
            </Button>

        </div>
    </div>
}


const ErfassungsauftragAuftragSingleEdit =
    connect((state) => {
        return {
            state,
        }
    }, {

        getUserDetails: orgAuth.getUserDetails,
        getSomedata: orgAuth.getSomedata,
        setSomedata: orgAuth.setSomedata,
        getAboSetLink: orgAuth.getAboSetLink,
        updateUserStamm: orgAuth.updateUserStamm,
        userRemoveFromOrg:orgAuth.userRemoveFromOrg,
        setUserPasswortAsAdmin:orgAuth.setUserPasswortAsAdmin


    })(Comp);


export default ErfassungsauftragAuftragSingleEdit;