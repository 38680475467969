import {apiUrl} from "../../settings";

const axios = require('axios');


const actions = {
    ROLLE_LISTE_LOAD_SUCCESS: 'ORGANISATION_ROLLE_LISTE_LOAD_SUCCESS',
    ROLLE_LISTE_LOAD_ERROR: 'ORGANISATION_ROLLE_LISTE_LOAD_ERROR',
    USER_LISTE_LOAD_SUCCESS: 'ORGANISATION_USER_LISTE_LOAD_SUCCESS',
    USER_Einladung_LISTE_LOAD_SUCCESS: 'ORGANISATION_USER_Einladung_LISTE_LOAD_SUCCESS',
    USER_LISTE_LOAD_ERROR: 'ORGANISATION_USER_LISTE_LOAD_ERROR',
    ROLLE_SINGLE_LOAD_SUCCESS: 'ORGANISATION_ROLLE_SINGLE_LOAD_SUCCESS',
    ROLLE_SINGLE_LOAD_ERROR: 'ORGANISATION_ROLLE_SINGLE_LOAD_ERROR',
    ORG_LOAD_SUCCESS: 'ORGANISATION_ORG_LOAD_SUCCESS',
    ORG_ADD_USER_VALIDATE_SUCCESS: 'ORG_ADD_USER_VALIDATE_SUCCESS',
    Einrichtung_LISTE_LOAD_SUCCESS: "ORG_Einrichtung_LISTE_LOAD_SUCCESS",


    getEinrichtungList: () => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "org/einrichtung/list", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                dispatch({type: actions.Einrichtung_LISTE_LOAD_SUCCESS, payload: x.data});
                return x.data

            }).catch(x => {
                throw false

            })
        }


    },getAboSetLink: () => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "org/getAboSetLink", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return x.data

            }).catch(x => {
                throw false

            })
        }


    },all_supportTickets: () => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "org/all_supportTickets", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return x.data

            }).catch(x => {
                throw false

            })
        }


    },sendSupportTicket: (data) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "org/sendSupportTicket",data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return x.data

            }).catch(x => {
                throw false

            })
        }


    },setSomedata: (field,value) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "auth/setSomedata",{field,value}, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                console.log(x.data)
                return x.data

            }).catch(x => {
                throw false

            })
        }


    },getSomedata: (field) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "auth/getSomedata",{field}, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                console.log(x.data)
                return x.data

            }).catch(x => {
                throw false

            })
        }


    },getEinrichtungBerichtList: (id) => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "/einrichtung/aquaBerichte/"+id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return x.data

            }).catch(x => {
                throw false

            })
        }


    },get_org_subgruppenCalc: () => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "/org/get_org_subgruppenCalc/", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return x.data

            }).catch(x => {
                throw false

            })
        }


    },getEinrichtungOneBericht: (id,data) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "/einrichtung/getOneAquaBericht/"+id,data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return x.data

            }).catch(x => {
                throw false

            })
        }


    },getEinrichtungDetails: (id) => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "einrichtung/details/"+id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {



                return x.data

            }).catch(x => {
                throw false

            })
        }


    },getUserDetails: (id) => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "org/user/get/"+id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {



                return x.data

            }).catch(x => {
                throw false

            })
        }


    },updateUserStamm: (id,data) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "org/user/updateStamm/"+id,data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {



                return x.data

            }).catch(x => {
                throw false

            })
        }


    },setUserPasswortAsAdmin: (data) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "org/user/set/password",data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {



                return x.data

            }).catch(x => {
                throw false

            })
        }


    },updateEinrichtung: (id,data) => {

        return (dispatch, getState) => {

            let request = apiUrl + "einrichtung/update/"+id

            if(id==-1){
                request = apiUrl + "einrichtung/add"
            }

            return axios.post(request,data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {



                return x.data

            }).catch(x => {
                console.log(x)
                throw false

            })
        }


    }, getEinrichtungBewohnerList: (id) => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "einrichtung/details/" + id + "/bewohners/list", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return x.data

            }).catch(x => {
                throw false

            })
        }


    }, getEinrichtungAuftragList: (id) => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "einrichtung/details/" + id + "/auftrag/list", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return x.data

            }).catch(x => {
                throw false

            })
        }


    }, pullToServer_bewohnerchanges: (changes) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "einrichtung/pullToServer/bewohnerchanges", changes, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return x.data

            }).catch(x => {
                throw false

            })
        }


    },
    getRolleList: () => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "org/rolle/list", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                console.log(x.data)

                dispatch({type: actions.ROLLE_LISTE_LOAD_SUCCESS, payload: x.data});
                return true

            }).catch(x => {
                dispatch({type: actions.ROLLE_LISTE_LOAD_ERROR});
                throw false

            })
        }


    },
    getRolleSingle: (id) => {

        return (dispatch, getState) => {


            if (id === undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({type: actions.ROLLE_SINGLE_LOAD_SUCCESS, payload: {rechte: []}});
                    resolve()
                });
            }

            return axios.get(apiUrl + "rolle/get/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                dispatch({type: actions.ROLLE_SINGLE_LOAD_SUCCESS, payload: x.data});
                return x

            }).catch(x => {
                dispatch({type: actions.ROLLE_SINGLE_LOAD_ERROR});
                throw x

            })
        }


    }, setDefaultRolle: (id) => {

        return (dispatch, getState) => {


            if (id === undefined) {
                throw null;
            }

            return axios.get(apiUrl + "org/rolle/set/default/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {

                dispatch({type: actions.ORG_LOAD_SUCCESS, payload: x.data});


                return x

            }).catch(x => {
                throw x

            })
        }


    }, loadOrg: () => {

        return (dispatch, getState) => {


            return axios.get(apiUrl + "org/get", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {

                dispatch({type: actions.ORG_LOAD_SUCCESS, payload: x.data});


                return x

            }).catch(x => {
                throw x

            })
        }


    },
    validateNewUsers: (data) => {

        return (dispatch, getState) => {


            return axios.post(apiUrl + "org/users/new/validate", data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {

                dispatch({type: actions.ORG_ADD_USER_VALIDATE_SUCCESS, payload: x.data});


                return x

            }).catch(x => {
                throw x

            })
        }


    },
    addNewUsers: (data) => {

        return (dispatch, getState) => {


            return axios.post(apiUrl + "org/users/new/add", data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {

                dispatch({type: actions.ORG_LOAD_SUCCESS, payload: x.data});


                return x

            }).catch(x => {
                throw x

            })
        }


    },
    saveRolle: (data) => {

        return (dispatch, getState) => {

            if (data._id == undefined) {
                return axios.post(apiUrl + "rolle/new", data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                    dispatch({type: actions.ROLLE_SINGLE_LOAD_SUCCESS, payload: x.data});

                    return x

                }).catch(x => {
                    dispatch({type: actions.ROLLE_SINGLE_LOAD_ERROR});
                    throw x

                })
            } else {
                return axios.post(apiUrl + "rolle/update/" + data._id, data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                    dispatch({type: actions.ROLLE_SINGLE_LOAD_SUCCESS, payload: x.data});
                    return x

                }).catch(x => {
                    dispatch({type: actions.ROLLE_SINGLE_LOAD_ERROR});
                    throw x

                })
            }
            return new Promise((resolve, reject) => {
                resolve();
            });

        }


    },

    getUserList: () => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "org/user/list", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                dispatch({type: actions.USER_LISTE_LOAD_SUCCESS, payload: x.data});
                return x.data

            }).catch(x => {
                dispatch({type: actions.USER_LISTE_LOAD_ERROR});
                throw false

            })
        }


    },
    userRemoveFromOrg: (id) => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "org/user/remove/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return true

            }).catch(x => {

                throw false

            })
        }


    },
    userEinladungAdd: (email) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "org/user/einladung/add", {email}, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                return true

            }).catch(x => {

                throw false

            })
        }


    },
    userEinladungList: (email) => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "org/user/einladung/list", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {


                dispatch({type: actions.USER_Einladung_LISTE_LOAD_SUCCESS, payload: x.data});


                return true

            }).catch(x => {

                throw false

            })
        }


    },
    saveUsers: (list) => {

        return (dispatch, getState) => {

            const users = list.filter(x => x.changeRolle).map(x => ({_id: x._id, rolle: x.rolle}))

            return axios.post(apiUrl + "org/users/update/rolle", users, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                console.log(x.data)
            })
        }


    },

};
export default actions;
