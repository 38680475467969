import actions from "./actions";

const initState = {token: null, data: {}};

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return {
                token: action.token,
                data: action.data
            };
        case actions.LOGOUT:
            return initState;
        default:
            return state;
    }
}
