import React, {Component} from 'react';
import "react-tabs/style/react-tabs.css";
import {connect} from 'react-redux';
import orgAuth from "../../../../redux/organisation/actions"
import {Modal, Drawer, Button, Col, Input} from 'antd';
import Field from "./field";
import Row from "antd/es/grid/row";
import IntlMessages from "../../../utility/intlMessages";
import ContentHolder from "../../../utility/contentHolder";
import Box from "../../../utility/box";

/**
 *Die Komponente verarbeitet die gesammten Stammdaten des Auftrages.
 **/



const log = (type) => console.log.bind(console, type);

class Comp extends Component {


    constructor(params) {
        super(params)

        this.updateSingleField = this.updateSingleField.bind(this)


    }

    state = {
        single:{},
        id: null
    }


    loadEveryThing() {
        let id = this.state.id;
        let wait = [];

      wait.push(this.props.getAboSetLink())

        wait.push(this.props.getSomedata("test"))
        wait.push(this.props.setSomedata("test",Date.now().toString()))

        return Promise.all(wait)
    }

    componentDidMount() {
        let id = this.props.id;


        this.setState({id}, () => {
            this.loadEveryThing()
        })
    }

    updateSingleField(value, what) {

        const single = {...this.state.single}
        single[what] = value;
        this.setState({single});
    }


    render() {
        const single = {...this.state.single}


        return <div>





                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >





                </div>
        </div>


    }
}


const ErfassungsauftragAuftragSingleEdit =
    connect((state) => {
        return {
            state,
        }
    }, {

        getUserDetails: orgAuth.getUserDetails,
        getSomedata: orgAuth.getSomedata,
        setSomedata: orgAuth.setSomedata,
        getAboSetLink: orgAuth.getAboSetLink,
        updateUserStamm: orgAuth.updateUserStamm,
        userRemoveFromOrg:orgAuth.userRemoveFromOrg,
        setUserPasswortAsAdmin:orgAuth.setUserPasswortAsAdmin


    })(Comp);


export default ErfassungsauftragAuftragSingleEdit;