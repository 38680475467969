import React, {Component} from 'react';
import "react-tabs/style/react-tabs.css";

import {Drawer} from 'antd';
import Core from "./index"
import IntlMessages from "../../../utility/intlMessages";

/**
 *Die Komponente verarbeitet die gesammten Stammdaten des Auftrages.
 *
 *
 * Folgende felder werden erstmal rausgelassen, Schnittstelle Zusatzindikatoren
 */


export default function (props) {


        return <Drawer
            width={760}
            title={"Thallux-Support"}
            visible={true}
            onClose={() => {
                props.onClose()
            }}
            footer={[]}

        >

            <Core {...props} />


        </Drawer>


    }


