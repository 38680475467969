import {Button} from "antd";
import React,{useState} from "react";
import SupportAnfrage from "../../components/thallux/newComponents/SupportAnfrageList/drawer"
export default (props)=>{

    const [showModal,set_showModal]=useState(false)

    return  <div style={{padding:10}}>
        {showModal&&<SupportAnfrage onClose={()=>{
            set_showModal(false)}
        }/>}
        <Button type={"dashed"} onClick={()=>{
            set_showModal(true)
        }} block>Thallux-Support</Button>
    </div>
}