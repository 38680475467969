import React, {useEffect, useState} from "react";

import {versionsConfig} from "../../configs"
import {Button} from "primereact/button";
import Werbung from "./../../components/thallux/newComponents/Werbemodal/modal"
export default function (props) {

    let bildURL = versionsConfig.appLinkQRCode;
    let action = () => {
        window.location.href = versionsConfig.appLink
    }
    let buttonLabel = "Link zur App"

    let werbungView = []

    let initShow = false;

    let [showWerbung, setShowWerbung] = useState(false)

    if(window.thalluxData.org.aboStatus=="free"){
       bildURL= "https://www.thallux.de/wp-content/uploads/2019/12/premimum.png";
       buttonLabel="Jetzt Premium sichern"
        action=()=>{
            setShowWerbung(true)
        }

        useEffect(()=>{
            setShowWerbung(true)

        },[])

    }


    if(showWerbung){
        werbungView= <Werbung onClose={()=>setShowWerbung(false)}/>


    }

    return <div style={{padding: 20,textAlign:"center"}}>

        {werbungView}
        <h2 style={{color: "#fff"}}>Applink:</h2>
        <br/>
        <img style={{width: "100%",maxWidth:"400px"}} src={bildURL}/>
        <br/>
        <Button className={"p-button-raised"} style={{width: "100%"}} label={buttonLabel} onClick={action}/>


    </div>
}