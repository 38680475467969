import {all, takeEvery, put, fork} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {getToken, clearToken} from '../../helpers/utility';
import actions from './actions';


export function* loginRequest() {
    yield takeEvery('LOGIN_REQUEST', function* ({payload}) {


    });
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
        console.log("test", payload)

        yield localStorage.setItem('token', payload.token);
    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function* () {
    });
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        clearToken();
        yield put(push('/'));
    });
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        const token = getToken().get('idToken');
        if (token) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                token,
                profile: 'Profile',
            });
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(loginError),
        fork(logout),
    ]);
}
