const configs = require("../configs")


const apiUrl = configs.apiUrl

const siteConfig = {
    siteName: 'Thallux Qualtiy One',
    siteIcon: 'ion-flash',
    footerText: 'Thallux Quality ©2019 Created by Thallux GmbH'
};

const themeConfig = {
    topbar: 'themedefault',
    sidebar: 'themedefault',
    layout: 'themedefault',
    theme: 'themedefault'
};
const language = 'deutsch';
const AlgoliaSearchConfig = {
    appId: '',
    apiKey: ''
};
const Auth0Config = {
    domain: '',
    clientID: '',
    allowedConnections: ['Username-Password-Authentication'],
    rememberLastLogin: true,
    language: 'en',
    closable: true,
    options: {
        auth: {
            autoParseHash: true,
            redirect: true,
            redirectUrl: 'http://localhost:3000/auth0loginCallback'
        },
        languageDictionary: {
            title: 'Isomorphic',
            emailInputPlaceholder: 'demo@gmail.com',
            passwordInputPlaceholder: 'demodemo'
        },
        theme: {
            labeledSubmitButton: true,
            logo: '',
            primaryColor: '#E14615',
            authButtons: {
                connectionName: {
                    displayName: 'Log In',
                    primaryColor: '#b7b7b7',
                    foregroundColor: '#000000'
                }
            }
        }
    }
};
const firebaseConfig = {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: ''
};
const googleConfig = {
    apiKey: '' //
};
const mapboxConfig = {
    tileLayer: '',
    maxZoom: '',
    defaultZoom: '',
    center: []
};
export {
    siteConfig,
    themeConfig,
    language,
    AlgoliaSearchConfig,
    Auth0Config,
    apiUrl,
    firebaseConfig,
    googleConfig,
    mapboxConfig
};
