import React, {useState,useEffect} from 'react';
import "react-tabs/style/react-tabs.css";
import {connect} from 'react-redux';
import orgAuth from "../../../../redux/organisation/actions"
import {Modal, Drawer, Button, Col, Input,notification} from 'antd';
import Field from "./field";
import Row from "antd/es/grid/row";
import IntlMessages from "../../../utility/intlMessages";
import ContentHolder from "../../../utility/contentHolder";
import Box from "../../../utility/box";
import axios from "axios"
/**
 *Die Komponente verarbeitet die gesammten Stammdaten des Auftrages.
 **/



const log = (type) => console.log.bind(console, type);

function Comp(props){


    const [url,setUrl]= useState("")


    useEffect(()=>{
        (props.getAboSetLink().then(x=>{
            setUrl(x.url)
        }))
    },[])
    return <div style={{height:"800px"}}>
        <iframe src={"https://www.thallux.de/aktion-internal-princing/"} style={{width:"100%",height:"100%",overflow:"hide"}}></iframe>
        <div
            style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }}
        >

<Button type={"primary"} onClick={()=>{
    axios.get(url).then(x=>{
        notification.open({
            message: 'Premium wurde Bestellt',
            description:
                'Wir haben Ihre Bestellung erhalten und sie direkt für Premium Freigeschaltet, wenn sie sich Aus- und wieder Einloggen haben sie alle Funktionenen. Ein Vertriebsmitarbeiter wird sich dann umgehend bei Ihnen melden. ',
            onClick: () => {
                console.log('Notification Clicked!');
                props.onClose()
            },
        });

    }).catch(x=>{
        console.log(x)
    })
}}>Bestellen</Button>



        </div>
    </div>
}


const ErfassungsauftragAuftragSingleEdit =
    connect((state) => {
        return {
            state,
        }
    }, {

        getUserDetails: orgAuth.getUserDetails,
        getSomedata: orgAuth.getSomedata,
        setSomedata: orgAuth.setSomedata,
        getAboSetLink: orgAuth.getAboSetLink,
        updateUserStamm: orgAuth.updateUserStamm,
        userRemoveFromOrg:orgAuth.userRemoveFromOrg,
        setUserPasswortAsAdmin:orgAuth.setUserPasswortAsAdmin


    })(Comp);


export default ErfassungsauftragAuftragSingleEdit;