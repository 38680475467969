import React, {Component} from 'react';
import "react-tabs/style/react-tabs.css";
import {connect} from 'react-redux';
import orgAuth from "../../../../redux/organisation/actions"
import qiAction from "../../../../redux/qi/actions"
import {Drawer} from 'antd';
import Core from "./index"

/**
 *Die Komponente verarbeitet die gesammten Stammdaten des Auftrages.
 *
 *
 * Folgende felder werden erstmal rausgelassen, Schnittstelle Zusatzindikatoren
 */


class Comp extends Component {


    constructor(params) {
        super(params)


    }


    render() {


        return <Drawer
            width={720}
            title={"Organisation"}
            visible={true}
            onClose={() => {
                this.props.onClose()
            }}
            footer={[]}

        >

            <Core  {...this.props} />

        </Drawer>


    }
}


export default Comp;