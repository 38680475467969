import React, {useEffect, useState} from "react"
import {Button, Table, Popover, Modal,Icon} from "antd";
import {useQuery} from "@apollo/react-hooks";
import _ from "underscore"
import {gql} from "apollo-boost"
import {connect} from "react-redux";
import orgAuth from "../../../../redux/organisation/actions";
import Authactions from "../../../../redux/auth/actions";
import TicketSingle from "./../TicketSingle/drawer"
import { Select ,Input,Form} from 'antd';
import moment from "moment";
import "moment/locale/de";
import {statusEnumArray} from "../../../../configs"


const get_all_infos=gql`
    query PULL_TICKET_LIST {

        me{
            organisation{
                einrichtungs{
                    name
                    _id
               
            }tickets{
                ownerId
                internal
                    _id
                status
                    ownerName

                    subject
                archiv
            }
            users{
                vorname
                nachname
                loginTag
                _id
            }
        } }
        

    }
`

const App = (props)=>{


    const [selectId,set_selectId] = useState(null)


    const query_data = useQuery(get_all_infos,{context:{headers: {
                authorization:  "Bearer "+props.state.Auth.token
            }},variables:{_id:props.auftragId}})




    if(query_data.loading||query_data.error){
        return <div></div>
    }

    return <div>



        {selectId&&<TicketSingle onClose={()=>set_selectId(null)} selectId={selectId} users={query_data.data.me.organisation.users} einrichtungs={query_data.data.me.organisation.einrichtungs} />}


        <div style={{padding:20}}></div>

        <Table pagination={false} dataSource={query_data.data.me.organisation.tickets} columns={[
            {key:"edit",dataIndex:"edit",title:"",render:(v,item)=><Icon onClick={()=>{
                    set_selectId(item._id)
                }} type={"edit"}/>},
            {key:"internal",dataIndex:"internal",title:"",render:v=>v?"Intern":"Extern"},
            {key:"status",dataIndex:"status",title:"Status",render:v=>{
                switch (v) {
                    case 0:
                        return "offen"
                    case 1:
                        return "in Bearbeitung"
                    case 2:
                        return "Rückfrage an Steller"
                    case 3:
                        return "Abgeschlossen"
                }
                return ""
                }},
            {key:"einrichtungId",dataIndex:"einrichtungId",title:"Einrichtung",render:(v,item)=>{
                    if(v){
                        let user = query_data.data.me.organisation.einrichtungs.find(x=>x._id == item.einrichtungId)
                        return user.name

                    }else{
                        return "Keine"
                    }
                }},
            {key:"ownerId",dataIndex:"ownerId",title:"Ersteller",render:(v,item)=>{
                if(v){
                    let user = query_data.data.me.organisation.users.find(x=>x._id == item.ownerId)
                    try{
                        return user.vorname+" "+user.nachname

                    }catch (e) {
                        return "Nutzer wurde entfernt"
                    }

                }else{
                    return item.owner.name
                }


                }},

            {key:"subject",dataIndex:"subject",title:"Anliegen"},
        ]}/>
        <div
            style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }}
        >






            <Button onClick={props.onClose} style={{marginRight: 8}}>
                Abbrechen
            </Button>






        </div>

    </div>

}



const Comp =
    connect((state) => {
        return {
            state,
        }
    }, {
        all_supportTickets:orgAuth.all_supportTickets,
        checkAllow:Authactions.checkAllow

    })(App);


export default Comp;