import {apiUrl} from "../../settings";
import canHelper from "../../helpers/can";
import jwt from "jsonwebtoken";

const axios = require('axios');
const _ = require("underscore")

function helpSetOrgGlobal(_,decodeTokenData){


    if(window.thalluxData == undefined){
        window.thalluxData ={}
    }
    window.thalluxData.org ={aboStatus:decodeTokenData.org.aboStatus,demoEnd:decodeTokenData.org.demoEnd}
}

const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    checkAuthorization: () => ({type: actions.CHECK_AUTHORIZATION}),
    login: (email, password) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "auth/login", {email, password}, {}).then(x => {
                if (x.status == 200) {

                    const decoded = jwt.decode(x.data.token)
                    helpSetOrgGlobal(getState,decoded)
                    localStorage.setItem("auth.token", x.data.token);
                    dispatch({
                        type: actions.LOGIN_SUCCESS,
                        token: x.data.token,
                        data: decoded
                    });
                    return x
                }
            }).catch(x => {
                throw dispatch({type: actions.LOGIN_ERROR});
            })
        }


    },

    checkToken: (token) => {

        return (dispatch, getState) => {
            return axios.get(apiUrl + "qi/auftrag/list", {headers: {Authorization: "Bearer " + token}}).then(x => {
                if (x.status == 200) {

                    const decoded = jwt.decode(token)

                    dispatch({
                        type: actions.LOGIN_SUCCESS,
                        token: token,
                        data: decoded
                    });
                    helpSetOrgGlobal(getState,decoded)

                    return x
                }
            }).catch(x => {
                throw dispatch({type: actions.LOGIN_ERROR});
            })
        }


    }, register: (form) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "auth/register", form, {}).then(x => {

                return x

            }).catch(x => {
                throw x.response;
            })
        }


    },
    passwortVergessen_setPasswort: (form) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "auth/password/set", form, {}).then(x => {

                return x

            }).catch(x => {
                throw x.response;
            })
        }


    },

    passwortVergessen_SendMail: (form) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "auth/password/forget", form, {}).then(x => {

                return x

            }).catch(x => {
                throw x.response;
            })
        }


    },
    logout: () => {
        localStorage.setItem("auth.token", null);
        return (
            {
                type: actions.LOGOUT,
            })
    },
    checkAllow(filters) {

        return (dispatch, getState) => {
            const data = getState().Auth.data;

            if(typeof filters != "object"){
                filters = [filters]
            }

            return (_.reduce(filters,(total,item)=>(canHelper.can(data.rechteString,item)&&total),true))
        }
    }
};
export default actions;
