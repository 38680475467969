import React, {Component} from 'react';
import "react-tabs/style/react-tabs.css";
import {connect} from 'react-redux';
import orgAuth from "../../../../redux/organisation/actions"
import qiAction from "../../../../redux/qi/actions"
import {Modal} from 'antd';
import Core from "./index"

/**
 *Die Komponente verarbeitet die gesammten Stammdaten des Auftrages.
 *
 *
 * Folgende felder werden erstmal rausgelassen, Schnittstelle Zusatzindikatoren
 */


class Comp extends Component {


    constructor(params) {
        super(params)


    }


    render() {


        return <Modal
            width={"90%"}
            style={{height:"calc(100vh - 50px)",top:"20px"}}
            title={"Holen Sie sich Premium"}
            visible={true}
            onCancel={() => {
                this.props.onClose()
            }}
            footer={[]}

        >

            <Core  {...this.props} />

        </Modal>


    }
}


export default Comp;