import React, {useEffect, useState} from 'react'
import Tour from 'reactour'
import $ from "jquery";
import {connect} from "react-redux";
import orgAction from "../../redux/organisation/actions";

function App(props){
    // ...

    const [show,setShow] = useState(false)
    const [update,setUpdate] = useState("")
    const [goToStep,setgoToStep] = useState(0)

    //return <div></div>
    useEffect(()=>{

        if(window.location.pathname==="/dashboard/"){
            console.log("da")

            props.getSomedata("tour-start-1").then(x=>{

if(x.value==="disabled") {
             setShow(false)
}else{
    setShow(true)
    setUpdate(update+".")


}

            }).catch(x=>{
                console.log(x)
            })

        }else{
            setShow(false);
        }


    },[])

    const steps = [
        {
            selector: '.first-step',
            style:{
                maxWidth:"600px"
            },
            content: ()=>{
                return <div>
                    <h2>Willkommen auf Thallux Quality</h2>
                    <p>Im folgendem Video erklären wir kurz den richtigen Umgang mit Thallux Quality,<br/> anschließend führen wir Sie, durch die Funktionen.</p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/y1STk7DvVY8" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            },
        },
        {selector:".dashboard-einrichtung-action",
            content: 'Als erstes Schauen wir uns die Stammdaten einer Einrichtung an.',
            updateDelay:5,
            action:()=>{
            $(".dashboard-einrichtung-action >a ").bind("click",()=>{
                setgoToStep(2)

                setUpdate(update+".")

            })

            }

        },
        {
            selector: '.actionListEinrichtungStammdaten',
            updateDelay:5,
            action:()=>{
                $(".actionListEinrichtungStammdaten >a ").bind("click",()=>{

                    setgoToStep(3)

                    setUpdate(update+".")

                })

            },
            content: 'Über diesen Button können neue Aufträge angelegt werden.',
        },
        {
            selector: '.ant-drawer-content',
            updateDelay:1,
            action:()=>{
                setTimeout(()=>{
                    setUpdate(update+".")

                },200)
            },

            content: 'Hier können die Stammdaten der Einrichtung angepasst werden.',
        },
        {

            action:()=>{
                setShow(false)

                props.setSomedata("tour-start-1","disabled").then(x=>{
                    //setShow(false)
                })

            },

            content: 'Ende',
        },{

            action:()=>{


            },

            content: 'Ende',
        },
        // ...
    ]
    return   (
<>
{ /* other stuff */}
<Tour
    closeWithMask={false}
steps={steps}
    goToStep={goToStep}
    update={update}
isOpen={show}
onRequestClose={()=>{
    props.setSomedata("tour-start-1","disabled")
    setShow(false)}} />
</>
)
}


export default connect(
    state => (state),
    {
        setSomedata:orgAction.setSomedata,
        getSomedata:orgAction.getSomedata

    }
)(App);

