import {sidebarShow} from "../../settings/constants";

var options = [
    {
        key: '',
        label: 'sidebar.dashboards',
        filter: [2],
        leftIcon: 'ion-laptop',

    },/*{
        key: 'dashboards',
        label: 'sidebar.dashboards',
        filter:[2],
        leftIcon: 'ion-laptop',

    },*/
    /*{
        key: 'anfrages',
        label: 'sidebar.anfrages',
        filter: [2],
        leftIcon: 'ion-chatbubbles',

    },*/
    {
        key: 'einrichtungs',
        label: 'sidebar.einrichtung',
        filter: [50],
        leftIcon: 'ion-home',

    },
    /*
    {
        key: 'qi',
        label: 'sidebar.qiAuftrag',
        filter: [2],
        leftIcon: 'ion-eye',

    },*/
    {
        key: 'qm',
        label: 'sidebar.qmAuftrag',
        filter: [""],
        leftIcon: 'ion-clipboard',
        children: [
            {
                key: 'qmListe',
                label: 'sidebar.qmAuftragMeineListe',
            },
            {
                key: 'qmNeu',
                label: 'sidebar.qmAuftragNeu',
            },
        ],
    }

    , {
        key: 'organisation',
        label: 'sidebar.einstellungen',
        filter: [51],

        leftIcon: 'ion-settings',
        children: [
            {
                filter: [16],
                key: 'organisation/rolle',
                label: 'sidebar.organisation.rolle',
            },
            {
                key: 'organisation/user',
                label: 'sidebar.organisation.user',
            },
            {
                key: 'einrichtungs',
                label: 'sidebar.einrichtung',
                filter: [2],
                leftIcon: 'ion-home',

            },{
                key: 'linktoapp',
                label: 'sidebar.linktoapp',
                filter: [2],
                leftIcon: 'ion-qrcode',

            },
        ],
    }
    ,

];


export default options;
