import actions from "./actions";

const initState = {
    auftragList: [],
    qiErfassungs: {},
    single: {erfassungs: [], anfrages: [], workers: []},
    singleChanged: false,
    bewohnerChange: false,
    statsPerWorker: {},
    singleAuswertung: {anzahlInStatus: []}
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.AUFTRAG_GET_LIST_SUCCESS:
            return {
                ...state,
                auftragList: action.auftragList
            };

        case actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS:
            return {
                ...state,
                singleAuswertung: {...action.singleAuswertung}
            };

        case actions.AUFTRAG_GET_SINGLE_getStatsPerWorker_SUCCESS:
            return {
                ...state,
                statsPerWorker: {...action.payload}
            };


        case actions.AUFTRAG_GET_SINGLE_SUCCESS:
            return {
                ...state,
                single: action.single,
                singleUnsaved: false
            };

        case actions.AUFTRAG_SET_SINGLE:
            const a = {...action}
            delete a.type;
            console.log(a)
            return {
                ...state,
                ...a

            };

        case actions.QI_ERFASSUNG_SET:

            return {
                ...state,
                qiErfassungs: action.payload

            };
        case actions.LOGOUT:
            return initState;
        default:
            return state;
    }
}
