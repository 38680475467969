import {apiUrl} from "../../settings";
import canHelper from "../../helpers/can"
const axios = require('axios');
const _ = require("underscore");

const actions = {
    AUFTRAG_GET_LIST_SUCCESS: 'QI_AUFTRAG_GET_LIST_SUCCESS',
    AUFTRAG_GET_SINGLE_SUCCESS: 'QI_AUFTRAG_GET_SINGLE_SUCCESS',
    AUFTRAG_GET_SINGLE_Auswertung_SUCCESS: 'QI_AUFTRAG_GET_SINGLE_Auswertung_SUCCESS',
    AUFTRAG_GET_SINGLE_getStatsPerWorker_SUCCESS: 'QI_AUFTRAG_GET_SINGLE_getStatsPerWorker_SUCCESS',
    AUFTRAG_SET_SINGLE: 'QI_AUFTRAG_SET_SINGLE',
    AUFTRAG_GET_LIST_ERROR: 'QI_AUFTRAG_GET_LIST_ERROR',
    QI_ERFASSUNG_SET: 'QI_ERFASSUNG_SET',

    getAuftragList: () => {

        return (dispatch, getState) => {

            let auth = getState().Auth


            if(!canHelper.can(auth.data.rechteString,0)){

                return axios.get(apiUrl + "qi/auftrag/list", {headers: {Authorization: "Bearer " + auth.token}}).then(ld => {
                    console.log(ld.data)
                    dispatch({
                        type: actions.AUFTRAG_GET_LIST_SUCCESS,
                        auftragList: ld.data,
                    });
                    return ld
                })
            }else{
                return axios.get(apiUrl + "org/qi/auftrag/list", {headers: {Authorization: "Bearer " + auth.token}}).then(x => {
                    if (x.status == 200) {

                        let list = x.data






                        dispatch({
                            type: actions.AUFTRAG_GET_LIST_SUCCESS,
                            auftragList: list,
                        });
                        return x
                    }
                }).catch(x => {
                    dispatch({type: actions.AUFTRAG_GET_LIST_ERROR});
                    return x

                })
            }


        }


    },

    /**
     * @description Setzt den Zentralen Redux für den Auftragssingle auf data, die daten werden aber nicht hochgeladen
     * @param data
     * @returns {Function}
     */

    setAuftragSingle: (data, options = {}) => {
        return (dispatch, getState) => {

            return new Promise((resolve, reject) => {
                let aa = {
                    type: actions.AUFTRAG_SET_SINGLE,
                    single: data,
                }
                if (options.bewohnerChange) {
                    aa.bewohnerChange = true
                }
                dispatch(aa);
                return resolve()
            })


        }
    },


    calcIndicator(id) {
        return (dispatch, getState) => {

            return axios.get(apiUrl + "qi/auftrag/calcIndikatoren/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}})
        }
    },

    getAuftragSingle: (id) => {

        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_SUCCESS,
                        single: {
                            "name": "",
                            "workers": [],
                            "qiErfassungs":
                                []
                        },
                    });
                    resolve();
                })

            } else {
                return axios.get(apiUrl + "qi/auftrag/get/" + id + "/all", {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        dispatch({
                            type: actions.AUFTRAG_GET_SINGLE_SUCCESS,
                            single: x.data,
                        });
                        return x.data
                    }
                }).catch(x => {
                    dispatch({type: actions.AUFTRAG_GET_LIST_ERROR});
                    return x

                })
            }

        }


    },
    getErfassungSince: (id,time) => {

        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    resolve([]);
                })

            } else {
                return axios.get(apiUrl + "qi/auftrag/getErfassungSince/" + id + "/"+time, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {



                        return x.data
                    }
                }).catch(x => {

                    throw x

                })
            }

        }


    },
    getAuftragSingleAuswertung: (id) => {


        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS,
                        singleAuswertung: {},
                    });
                    resolve();
                })

            } else {

                return axios.get(apiUrl + "qi/auftrag/auswertung/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        dispatch({
                            type: actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS,
                            singleAuswertung: x.data,
                        });
                        return x
                    }
                }).catch(x => {
                    dispatch({type: actions.AUFTRAG_GET_LIST_ERROR});
                    return x

                })
            }
        }


    },
    getStatsPerWorker: (id) => {


        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS,
                        singleAuswertung: {},
                    });
                    resolve();
                })

            } else {

                return axios.get(apiUrl + "qi/auftrag/getStatsPerWorker/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        dispatch({
                            type: actions.AUFTRAG_GET_SINGLE_getStatsPerWorker_SUCCESS,
                            payload: x.data,
                        });
                        return x
                    }
                }).catch(x => {
                    return x

                })
            }
        }


    },
    deleteAuftrag: (id) => {


        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS,
                        singleAuswertung: {},
                    });
                    resolve();
                })

            } else {

                return axios.get(apiUrl + "qi/auftrag/delete/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x;
                    }
                }).catch(x => {
                    throw x

                })
            }
        }


    },
    deleteQiErfassung: (id) => {


        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {

                    reject();
                })

            } else {

                return axios.get(apiUrl + "qi/auftrag/deleteSingleErfassung/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x;
                    }
                }).catch(x => {
                    throw x

                })
            }
        }


    },getQiErfassung: (id) => {


        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {

                    reject();
                })

            } else {

                return axios.get(apiUrl + "qi/erfassung/get/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x.data;
                    }
                }).catch(x => {
                    throw x

                })
            }
        }


    },
    pushToAuqa: (id) => {


        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS,
                        singleAuswertung: {},
                    });
                    resolve();
                })

            } else {

                return axios.get(apiUrl + "qi/auftrag/pushDataToAqua/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x.data;
                    }
                }).catch(x => {
                    throw x.response.data

                })
            }
        }


    },
    pushKommentarToAqua: (id) => {


        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS,
                        singleAuswertung: {},
                    });
                    resolve();
                })

            } else {

                return axios.get(apiUrl + "qi/auftrag/pushKommentarToAqua/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x.data;
                    }
                }).catch(x => {
                    throw x.response.data

                })
            }
        }


    },
    pushQiChanges: (data) => {


        return (dispatch, getState) => {



                return axios.post(apiUrl + "/qi/erfassung/changes",data, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x.data;
                    }
                }).catch(x => {
                    throw x.response.data

                })

        }


    },getStichtagArray: (bundesland) => {



        return (dispatch, getState) => {


            return axios.get(window.location.origin + "/stichtag/" + bundesland + ".json").then(x => {
                if (x.status == 200) {


                    return x.data;
                }
            }).catch(x => {
                throw x.response.data

            })
        }


    },
    getDataToAqua: (id) => {

        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS,
                        singleAuswertung: {},
                    });
                    resolve();
                })

            } else {

                return axios.get(apiUrl + "qi/auftrag/getAquaData/" + id, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x.data;
                    }
                }).catch(x => {
                    throw x

                })
            }
        }


    },
    readAnswerFromAqua: (id,pos) => {

        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS,
                        singleAuswertung: {},
                    });
                    resolve();
                })

            } else {

                return axios.get(apiUrl + "qi/auftrag/readAnswerFromAqua/"+id+"/"+pos, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x.data;
                    }
                }).catch(x => {
                    throw x

                })
            }
        }


    },
    setFestschreiben: (id,what) => {

        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_Auswertung_SUCCESS,
                        singleAuswertung: {},
                    });
                    resolve();
                })

            } else {

                let url = apiUrl + "qi/auftrag/setFestschreiben/" + id+"/"+((what)?1:0);
                return axios.get(url, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x.data;
                    }
                }).catch(x => {
                    throw x

                })
            }
        }


    },setArchiv: (id,what) => {

        return (dispatch, getState) => {

            if (id == undefined) {
                return new Promise((resolve, reject) => {

                    resolve();
                })

            } else {

                let url = apiUrl + "qi/auftrag/setArchiv/" + id+"/"+((what)?1:0);
                return axios.get(url, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {


                        return x.data;
                    }
                }).catch(x => {
                    throw x

                })
            }
        }


    },
    pull_qiErfassungs: (ids) => {
        return (dispatch, getState) => {
            return new Promise((resolve, reject) => {
                return axios.post(apiUrl + "qi/erfassung/list", ids, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(x => {
                    if (x.status == 200) {

                        let qiErfassungs = {...getState().Qi.qiErfassungs};
                        if (qiErfassungs == undefined) {
                            qiErfassungs = {};
                        }
                        _.each(x.data, item => {
                            qiErfassungs[item._id] = item
                        })
                        dispatch({type: actions.QI_ERFASSUNG_SET, payload: qiErfassungs})
                        resolve(qiErfassungs)
                    }
                }).catch(x => {
                    reject(x);

                })
            })
        }
    },
    duplicateAuftrag:(single)=>{
        return (dispatch, getState) => {

            return new Promise(resolve => {

                let request = axios.post(apiUrl + "qi/auftrag/new/", {einrichtung:single.einrichtung,name:"[Duplikat] "+single.name,stichtag:single.stichtag,erfassungEnde:single.erfassungEnde,workers:single.workers.map(x=>({item:x.item}))}, {headers: {Authorization: "Bearer " + getState().Auth.token}});

            request.then(x => {
                    return axios.post(apiUrl + "qi/auftrag/setErfassungs/" + x.data._id, _.map(single.qiErfassungs,erfassung=>{
                        erfassung = _.omit(erfassung,["auftrag","_id","user","timer","updatedAt","einrichtung","bewohner"])
                        erfassung.abnormalities = _.map(erfassung.abnormalities,abnor=>{
                              abnor = _.omit(abnor,"anfrage")
                            abnor.hasAnfrage = false
                            return abnor
                        })
                        return erfassung


                    }), {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(y => {
                        resolve(y)
                    });
            })
        })
        }

    },


    setErfassung: (auftrag_id, qiErfassung) => {
        if (!Array.isArray(qiErfassung)) {
            return new Promise((resolve, reject) => reject())
        }
        return (dispatch, getState) => {
            return axios.post(apiUrl + "qi/auftrag/setErfassungs/" + auftrag_id, qiErfassung, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(y => {
                return y.data;
            });
        }
    },

    setSingleErfassung: (auftrag_id, qiErfassung) => {

        return (dispatch, getState) => {
            return axios.post(apiUrl + "qi/auftrag/setSingleErfassung/" + auftrag_id, qiErfassung, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(y => {
                return y.data;
            });
        }
    },
    saveStammdaten: (single) => {
        return (dispatch, getState) => {


            let request;

            if (single._id != undefined) {
                request = axios.post(apiUrl + "qi/auftrag/update/" + single._id, single, {headers: {Authorization: "Bearer " + getState().Auth.token}})
            } else {
                request = axios.post(apiUrl + "qi/auftrag/new/", single, {headers: {Authorization: "Bearer " + getState().Auth.token}});

            }


            return request.then(x => {
                return x.data
            })
        }
    }
    , saveAuftrag: () => {

        return (dispatch, getState) => {


            const qi = getState().Qi;
            const single = {...qi.single};

            let request;

            if (single._id != undefined) {
                request = axios.post(apiUrl + "qi/auftrag/update/" + single._id, single, {headers: {Authorization: "Bearer " + getState().Auth.token}})
            } else {
                request = axios.post(apiUrl + "qi/auftrag/new/", single, {headers: {Authorization: "Bearer " + getState().Auth.token}});

            }


            return request.then(x => {
                if (qi.qiErfassungs) {
                    let id = single._id;
                    if (id == undefined) {
                        id = x._id
                    }

                    return axios.post(apiUrl + "qi/auftrag/setErfassungs/" + id, single.qiErfassungs, {headers: {Authorization: "Bearer " + getState().Auth.token}}).then(y => {
                        return x;
                    });
                } else {
                    return x
                }
            }).then(x => {
                if (x.status == 200) {

                    if (x.data.qiErfassungs == null) {
                        x.data.qiErfassungs = qi.single.qiErfassungs
                    }

                    dispatch({
                        type: actions.AUFTRAG_GET_SINGLE_SUCCESS,
                        single: x.data,
                    });
                    return x
                }
            }).catch(x => {
                console.log(x.response.data)
                throw x.response.data

            })
        }
    }

};
export default actions;
