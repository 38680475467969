import React, {useEffect, useState} from "react"
import {Button, Row,Col,Card, Comment, Tooltip} from "antd";
import {useApolloClient, useMutation, useQuery} from "@apollo/react-hooks";
import _ from "underscore"
import {gql} from "apollo-boost"
import {connect} from "react-redux";
import orgAuth from "../../../../redux/organisation/actions";
import Authactions from "../../../../redux/auth/actions";
import SupportAnfrageNeu from "./../SupportAnfrageNeu/drawer"
import { Select ,Input,Form} from 'antd';
import moment from "moment";
import "moment/locale/de";
import {statusEnumArray} from "../../../../configs"


const {Option}  = Select

const get_all_infos=gql`
    query GET_TICKET_INFO($_id:String) {

       ticket(_id:$_id){
           ownerId
           internal
           _id
           status
           subject
           internalNote
           archiv
           message
           ownerName
           answers
           
       }

    }
`

const App = (props)=>{


    const client = useApolloClient();


    const query_data = useQuery(get_all_infos,{context:{headers: {
                authorization:  "Bearer "+props.state.Auth.token
            }},variables:{_id:props.selectId}})


    const [sendUpdate] = useMutation(gql(`mutation($_id:String,$data:Ticket_updateInput){
  updateTicket(_id:$_id,data:$data){
    _id
  }
}`),{context:{headers: {
                authorization:  "Bearer "+props.state.Auth.token
            }},onCompleted:()=>{

set_updates({})
            client.resetStore()
        }})



    const onUpdate= (obj)=>{
        set_updates({...updates,...obj})
        set_ticket({...ticket,...obj})
    }
    const [ticket,set_ticket] = useState({})
    const [updates,set_updates] = useState({})


    useEffect(()=>{
        try{
            set_ticket(query_data.data.ticket)

        }catch (e) {

        }

    },[query_data.data])

    if(query_data.loading||query_data.error){
        return <div></div>
    }
    let owner = props.users.find(x=>x._id == ticket.ownerId)
    if(owner==undefined){
        owner = ticket.ownerName
    }else{
        owner = owner.vorname + " "+owner.nachname
    }


    return <div>

        <Row>
            <Col span={12}>
                <Row>
                    <Col span={24}>
                        <Card title={"Verlauf"}>
                            {_.map(ticket.answers,item=>{

                                let user = props.users.find(x=>x._id == item.userId)
                                if(user==undefined){

                                }else{
                                    user = user.vorname + " "+user.nachname

                                }

                                                           return <Comment
                                    author={<span>{user}</span>}

                                    content={
                                        <p>
                                          {item.text}
                                        </p>
                                    }
                                    datetime={
                                        <Tooltip title={moment(item.time).format('LLL')}>
                                            <span>{moment(item.time).fromNow()}</span>
                                        </Tooltip>
                                    }
                                />
                              return <p>{item.text}</p>
                            })}
                        </Card>
                    </Col>
                </Row>
                <Row>

                    <Col span={24}>
                        <Card title={"Neue Antwort"}>
                            <Input.TextArea rows={6} onChange={(v)=>onUpdate({antwort:v.currentTarget.value})} value={ticket.antwort}/>
                        </Card>
                    </Col>
                </Row>

            </Col>
            <Col span={12}>
                <Row>
                    <Col span={8}>
                        <Card title={"Ersteller"}>
                            {owner}<br/>
                            <label>Telefon:</label> {ticket.ownerTelefon}<br/>
                            <label>E-Mail:</label>{ticket.ownerEmail}
                        </Card>
                    </Col>

                    <Col span={16}>
                        <Card title={"Meta"}>

                            <Col span={12}>
                                <Form.Item label={"Archiv"}>
                            <Select value={ticket.archiv} style={{ width: 120 }} onChange={(v)=>onUpdate({archiv:v})}>
                                <Option value={true}>Ja</Option>
                                <Option value={false}>Nein</Option>
                            </Select>
                                </Form.Item>
                            </Col>
                              <Col span={12}>
                                <Form.Item label={"Status"}>
                            <Select value={ticket.status} style={{ width: 120 }} onChange={(v)=>onUpdate({status:v})}>
                                <Option value={0}>offen</Option>
                                <Option value={1}>in Bearbeitung</Option>
                                <Option value={2}>Rückfrage an Steller</Option>
                                <Option value={3}>Abgeschlossen</Option>
                            </Select>
                                </Form.Item>
                            </Col>




                        </Card>
                    </Col>

                </Row>


                <Row>
                    <Col span={24}>
                        <Card title={"Nachricht"}>
                            <h2>{ticket.subject}</h2>
                            {ticket.message}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Card title={"Interne Notiz"}>
                            <Input.TextArea rows={6} onChange={(v)=>onUpdate({internalNote:v.currentTarget.value})} value={ticket.internalNote}/>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>




        <div
            style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }}
        >






            <Button onClick={props.onClose} style={{marginRight: 8}}>
                Abbrechen
            </Button>


            {Object.keys(updates).length>0&&<Button type={"primary"} onClick={()=>{


                let data ={...updates}



                if(data.antwort){

                    if(!Array.isArray(ticket.answers)){
                        ticket.answers = []
                    }
                    data.answers = [...ticket.answers,{
                        userId:props.state.Auth.data.userId,
                        text:data.antwort,
                        time:new Date().toISOString()
                    }]

                    delete data.antwort
                    delete ticket.antwort
                }


                sendUpdate({context:{headers: {
                            authorization:  "Bearer "+props.state.Auth.token
                        }},variables:{_id:props.selectId,data}})
            }} style={{marginRight: 8}}>
                Speichern und Hochladen
            </Button>}






        </div>

    </div>

}



const Comp =
    connect((state) => {
        return {
            state,
        }
    }, {
        all_supportTickets:orgAuth.all_supportTickets,
        checkAllow:Authactions.checkAllow

    })(App);


export default Comp;