export function getView(width) {
    let newView = "MobileView";
    if (width > 1220) {
        newView = "DesktopView";
    } else if (width > 767) {
        newView = "TabView";
    }
    return newView;
}

const actions = {
    COLLPSE_CHANGE: "COLLPSE_CHANGE",
    COLLPSE_OPEN_DRAWER: "COLLPSE_OPEN_DRAWER",
    CHANGE_OPEN_KEYS: "CHANGE_OPEN_KEYS",
    TOGGLE_ALL: "TOGGLE_ALL",
    CHANGE_CURRENT: "CHANGE_CURRENT",
    CHANGE_SPINNER: "CHANGE_SPINNER_APP",
    SET_CHAT_UNREAD: "SET_CHAT_UNREAD",
    SET_isChatPopup: "SET_isChatPopup",
    CLEAR_MENU: "CLEAR_MENU",
    toggleCollapsed: () => ({
        type: actions.COLLPSE_CHANGE
    }),
    toggleAll: (width, height) => {
        const view = getView(width);
        const collapsed = view !== "DesktopView";
        return {
            type: actions.TOGGLE_ALL,
            collapsed,
            view,
            height
        };
    },
    toggleOpenDrawer: () => ({
        type: actions.COLLPSE_OPEN_DRAWER
    }),
    changeOpenKeys: openKeys => ({
        type: actions.CHANGE_OPEN_KEYS,
        openKeys
    }),
    changeCurrent: current => ({
        type: actions.CHANGE_CURRENT,
        current
    }), startChatPopup: conversation => {


        try {
            window.chatPopup = window.talkSession.createPopup(conversation, {keepOpen: false});
            window.chatPopup.mount({show: true});
            return {type: actions.SET_isChatPopup, payload: true};

        } catch (e) {
            console.log(e)
            return {type: actions.SET_isChatPopup, payload: false};

        }


    },
    setSpinner:(bool)=>{
        return (dispatch,getState)=>{
            dispatch({type: actions.CHANGE_SPINNER, payload: bool==true})

        }
    },
    setChatUnreads: (unreads) => {

        return {type: actions.SET_CHAT_UNREAD, payload: unreads}
    },
    clearMenu: () => ({type: actions.CLEAR_MENU})
};
export default actions;
