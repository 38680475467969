import img0 from "./image/erfassungsstatus/0.png"
import img1 from "./image/erfassungsstatus/1.png"
import img3 from "./image/erfassungsstatus/3.png"
import img4 from "./image/erfassungsstatus/4.png"
import img4a from "./image/erfassungsstatus/4a.png"

export const statusEnumArray = [
    {text: "Nicht Bearbeitet", color: "#d1cfd1",img:[img0]},
    {text: "Unvollständig", color: "#ffb049",img:[img1]},
    {text: "Logikfehler", color: "#ff110a",},
    {text: "Review", color: "#ff59de", img:[img3]},
    {text: "Vollständig", color: "#29ff34",img:[img4,img4a]},
];


export const ampelColors = {
    rot: "red",
    gelb: "#ffe267",
    gruen: "green"
}

export const versionsConfig = require("./versionsConfig");


let apiUrle = "";

apiUrle = versionsConfig.apiUrl;

export const apiUrl = apiUrle;

